import React from 'react';
import { Col, Row } from 'reactstrap';
import MetricCard from './MetricCard';
import { Filters } from './Filters';
import { shortFormatDate, humanizeTime } from '../../utils/timeFormat';
import {
  mapInterviewersToColor,
  allInterviewersColour,
} from '../../utils/participantsToColor';
import moment from 'moment';
import { Timedelta } from '../../utils/timedelta';
import { Chart as ChartOptions } from 'react-chartjs-2';
import { SelectOption } from '../../components/Select';
import { EmptyState } from '../../components/EmptyState';
import { SerializedDateRange } from '../../entities/SerializedDateRange';
import { Color, ScriptableScaleContext } from 'chart.js';
import { AnyObject } from 'chart.js/types/basic';
import { AnalyticsFiltersService } from '../../services/v1/interview_intelligence/Analytics';
import { FilterResult } from '../../components/MultiSelectAsyncFilter';
import { mapResultToSelectOption } from '../../utils/mapResultToSelectOption';
interface ChartData {
  labels: string[];
  values: number[] | { [key: number]: number[] }[];
}

interface DonutChartData {
  [key: number]: number;
}

interface Metrics {
  interview_automatic_questions: number;
  interview_count: number;
  interviews_duration: number;
  interactivity: number;
  talk_ratio: number;
  talk_ratio_over_time: ChartData;
  talk_speed: number;
  talk_speed_over_time: ChartData;
  longest_monologue: number;
  longest_monologue_over_time: ChartData;
  punctuality: number;
  punctuality_over_time: ChartData;
  interviews_count_over_time: ChartData;
  interview_quality_score: number;
  interview_quality_score_over_time: ChartData;
  overall_user_score: DonutChartData;
}
interface PropTypes {
  startDate: string;
  endDate: string;
  dateRange: SerializedDateRange;
  interviewerOptions: SelectOption[];
  metrics: Metrics;
  periodicity: string;
  benchmarkOptions: SelectOption[];
  benchmarks: Benchmarks;
  metricsTrend: Metrics;
}

interface Benchmarks {
  questions?: number;
  interview_duration?: number;
  interactivity?: number;
  talk_ratio: number;
  talk_speed: number;
  longest_monologue: number;
  punctuality: number;
  interview_quality_score?: number;
}

const BENCHMARK_LABELS = ['Best Practice', 'Company AVG', 'Customers AVG'];

export default React.memo(function AnalyticsPage(props: PropTypes) {
  function buildJobFilterByOptions(): [
    (search: string, page: number) => Promise<FilterResult>,
  ] {
    return [
      (name, page) =>
        mapResultToSelectOption(
          AnalyticsFiltersService.jobs({ name, page }),
          (filter) => filter.jobs,
          false,
        ),
    ];
  }
  const [jobLoadOptions] = buildJobFilterByOptions();

  const selectedInterviewers = props.interviewerOptions.filter(
    (opt) => opt.selected,
  );

  const orderedSelectedInterviewers = selectedInterviewers.sort(
    (a, b) =>
      (props.metrics.overall_user_score[b.value] != 0
        ? props.metrics.overall_user_score[b.value]
        : 0) -
      (props.metrics.overall_user_score[a.value] != 0
        ? props.metrics.overall_user_score[a.value]
        : 0),
  );

  const selectedBenchmark = props.benchmarkOptions.find((opt) => opt.selected);

  const selectedBenchmarkLabel = selectedBenchmark?.label;

  const bestPracticeColor = '#4AE5D2';

  const transparentColor = 'rgba(0,0,0,0)';

  const lightGray = '#F6F8FA';

  const noValueColors = [transparentColor, lightGray];

  const periodicityMapper = (date: moment.Moment) => {
    switch (props.periodicity) {
      case 'day':
        return date.format('MMM DD, YYYY');
      case 'week':
        return (
          date.format('MMM DD, YYYY') +
          ' - ' +
          date.add(6, 'days').format('MMM DD, YYYY')
        );
      case 'month':
        return date.format('MMM, YYYY');
      case 'year':
        return date.format('YYYY');
    }
  };

  const interviewerName = (interviewer: string) => {
    const interviewerOption =
      interviewer && selectedInterviewers.find((i) => i.value == interviewer);
    return interviewerOption?.label || interviewer || 'All Interviewers';
  };

  const metricData = (data: ChartData, interviewer: string, idx: number) => {
    const interviewerOption =
      interviewer && selectedInterviewers.find((i) => i.value == interviewer);
    if (data.values[idx]) {
      return interviewerOption
        ? data.values[idx][interviewerOption.value]
        : data.values[idx];
    }
  };

  const genericOptions = {
    fill: false,
    interaction: {
      intersect: false,
    },
    radius: 0,
  };

  const tooltipLabelFormatter = (
    originData: ChartData,
    format?: (x: number) => string,
    showInterviews = true,
  ) =>
    function (context) {
      // If this is one of Benchmark Label, Do not show on tooltip
      if (BENCHMARK_LABELS.includes(context.dataset.label)) {
        return null;
      }

      const interviewerLabel = interviewerName(context.dataset.label);
      const nrInterviews = metricData(
        props.metrics.interviews_count_over_time,
        context.dataset.label,
        context.dataIndex,
      );
      const originDataPoint =
        metricData(originData, context.dataset.label, context.dataIndex) ||
        context.formattedValue;
      const formattedValue =
        (format && format(originDataPoint)) || context.formattedValue;

      const labelLines = [` ${interviewerLabel}: ${formattedValue}`];
      if (nrInterviews && showInterviews) {
        labelLines.push(` Number of Interviews: ${nrInterviews}`);
        return labelLines;
      } else if (!showInterviews) {
        return labelLines;
      }
    };

  const chartLegends = {
    position: 'bottom' as const,
    align: 'start' as const,
    labels: {
      boxWidth: 10,
      boxHeight: 10,
      generateLabels: function (chart) {
        const items =
          ChartOptions.defaults.plugins.legend.labels.generateLabels(chart);
        return items.map((l) => ({ ...l, text: interviewerName(l.text) }));
      },
    },
  };

  const chartOptions = (tooltipLabelCallback, legendOn = true) => ({
    responsive: true,
    maintainAspectRatio: false,
    animation: false as const,
    plugins: {
      legend: {
        display: legendOn,
        ...chartLegends,
      },
      tooltip: {
        filter: function (tooltipItem) {
          return !noValueColors.includes(
            tooltipItem.dataset.backgroundColor[tooltipItem.dataIndex],
          );
        },
        callbacks: {
          title: function (context) {
            return (
              context[0] &&
              periodicityMapper(moment(context[0].label, 'MMM DD, YYYY'))
            );
          },
          label: tooltipLabelCallback,
        },
      },
    },
  });
  const interviewersToColor = mapInterviewersToColor(
    selectedInterviewers.map((v) => v.value),
  );

  const emptyDoughnutData = [
    {
      data: [100],
      backgroundColor: [lightGray],
    },
  ];

  const emptyChart = props.metrics.interview_quality_score === 0;

  const getAverageInterviewQualityScore = () => {
    let sum = 0;
    let interviewersWithScore = 0;

    selectedInterviewers.forEach((opt) => {
      sum = sum + props.metrics.overall_user_score[opt.value];
      if (props.metrics.overall_user_score[opt.value] > 0) {
        interviewersWithScore = interviewersWithScore + 1;
      }
    });

    if (interviewersWithScore > 0) {
      return Math.round(sum / interviewersWithScore);
    } else {
      return Math.round(props.metrics.interview_quality_score);
    }
  };

  function barChartWithBenchmarkOptions(
    metricData: ChartData,
    valueLabel: string,
    benchmarks: number,
  ) {
    const type = metricData.values[0] instanceof Object ? 'line' : 'bar';

    const options = {
      ...chartOptions(
        tooltipLabelFormatter(
          metricData,
          (v) => `${Math.round(v)}${valueLabel}`,
        ),
      ),
      scales: {
        y: {
          grid: {
            color: (ctx, _options) =>
              ctx.tick.value === 0 ? ChartOptions.defaults.borderColor : '#FFF',
          },
          ticks: {
            callback: function (value) {
              if (Math.floor(value) === value) {
                return value && value + valueLabel;
              }
            },
            stepSize: 20,
          },
        },
      },
      borderRadius: 5,
    };

    const labels = metricData.labels.map((d) => shortFormatDate(d));

    const datasets = [
      ...(!(metricData.values[0] instanceof Object)
        ? [
            {
              data: metricData.values as number[],
              backgroundColor: allInterviewersColour,
              barPercentage: 1.0,
            },
          ]
        : selectedInterviewers.map((opt) => {
            return {
              label: opt.value,
              data: metricData.values.map((v) => v[opt.value]),
              backgroundColor: interviewersToColor[opt.value],
              barPercentage: 1.0,
            };
          })),
      {
        type: 'line',
        label: selectedBenchmarkLabel,
        data: Array(metricData.labels.length).fill(benchmarks),
        backgroundColor: 'transparent',
        borderColor: bestPracticeColor,
        pointRadius: 0,
        borderWidth: 2,
      },
    ];

    return {
      type,
      options,
      data: {
        labels,
        datasets,
      },
    };
  }

  const qualityScore = {
    options: {
      ...chartOptions(
        tooltipLabelFormatter(
          {
            labels: Object.keys(props.metrics.overall_user_score),
            values: Object.values(props.metrics.overall_user_score),
          },
          (v) => `${Math.round(v)}%`,
          false,
        ),
        false,
      ),

      hoverBorderColor: transparentColor,
      cutout: selectedInterviewers.length <= 1 || emptyChart ? '75%' : '55%',
      radius: 90,
      borderRadius: emptyChart ? 0 : 32,
      borderWidth: emptyChart ? 0 : 2,
    },
    data: {
      labels: props.metrics.interview_quality_score_over_time.labels.map((d) =>
        shortFormatDate(d),
      ),
      datasets: emptyChart
        ? emptyDoughnutData
        : [
            ...(!(
              props.metrics.interview_quality_score_over_time
                .values[0] instanceof Object
            )
              ? [
                  {
                    data: [
                      props.metrics.interview_quality_score,
                      100 - props.metrics.interview_quality_score,
                    ],
                    backgroundColor: [allInterviewersColour, lightGray],
                  },
                ]
              : orderedSelectedInterviewers.slice(0).map((opt) => {
                  const score: number =
                    props.metrics.overall_user_score[opt.value];

                  return {
                    label: opt.value,
                    data: [score, 100 - score],
                    backgroundColor: [
                      interviewersToColor[opt.value],
                      lightGray,
                    ],
                  };
                })),
          ],
    },
  };

  const qualityScoreOverTime = barChartWithBenchmarkOptions(
    props.metrics.interview_quality_score_over_time,
    '%',
    props.benchmarks.interview_quality_score,
  );

  const speed = barChartWithBenchmarkOptions(
    props.metrics.talk_speed_over_time,
    ' WPM',
    props.benchmarks.talk_speed,
  );

  const ratio = {
    options: {
      ...chartOptions(
        tooltipLabelFormatter(
          props.metrics.talk_ratio_over_time,
          (v) => `${Math.round(v)}%`,
        ),
      ),
      scales: {
        y: {
          min: 0,
          grid: {
            display: false,
          },
          ticks: {
            callback: (value) => value && `${value}%`,
          },
        },
      },
    },
    data: {
      labels: props.metrics.talk_ratio_over_time.labels.map((d) =>
        shortFormatDate(d),
      ),
      datasets: [
        ...(!(props.metrics.talk_speed_over_time.values[0] instanceof Object)
          ? [
              {
                data: props.metrics.talk_ratio_over_time.values as number[],
                backgroundColor: allInterviewersColour,
                borderColor: allInterviewersColour,
                borderWidth: 2,
                lineTension: 0.3,
                segment: {
                  borderColor: allInterviewersColour,
                },
              },
            ]
          : selectedInterviewers.map((opt) => {
              return {
                label: opt.value,
                data: (
                  props.metrics.talk_ratio_over_time.values as {
                    [key: number]: number[];
                  }[]
                ).map((v) => v[opt.value]) as number[],
                backgroundColor: interviewersToColor[opt.value],
                borderColor: interviewersToColor[opt.value],
                borderWidth: 2,
                lineTension: 0.3,
                segment: {
                  borderColor: interviewersToColor[opt.value],
                },
              };
            })),
        {
          label: selectedBenchmarkLabel,
          data: [
            ...Array(props.metrics.talk_ratio_over_time.labels.length),
          ].map(() => props.benchmarks.talk_ratio),
          borderColor: bestPracticeColor,
          backgroundColor: 'transparent',
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
      options: genericOptions,
    },
  };

  const monologue = {
    options: {
      ...chartOptions(
        tooltipLabelFormatter(props.metrics.longest_monologue_over_time, (v) =>
          humanizeTime(v),
        ),
      ),
      scales: {
        y: {
          min: 0,
          ticks: {
            callback: function (value) {
              if (Math.floor(value) === value) {
                return value && value + 'm';
              }
            },
          },
          grid: {
            display: false,
          },
        },
      },
    },
    data: {
      labels: props.metrics.longest_monologue_over_time.labels.map((d) =>
        shortFormatDate(d),
      ),
      datasets: [
        ...(!(
          props.metrics.longest_monologue_over_time.values[0] instanceof Object
        )
          ? [
              {
                data: (
                  props.metrics.longest_monologue_over_time.values as number[]
                ).map((v) => v && Timedelta.fromSecs(v).toMins()),
                backgroundColor: allInterviewersColour,
                borderColor: allInterviewersColour,
                borderWidth: 2,
                lineTension: 0.3,
                segment: {
                  borderColor: allInterviewersColour,
                },
              },
            ]
          : selectedInterviewers.map((opt) => {
              return {
                label: opt.value,
                data: (
                  props.metrics.longest_monologue_over_time.values as {
                    [key: number]: number[];
                  }[]
                ).map(
                  (v) =>
                    v[opt.value] && Timedelta.fromSecs(v[opt.value]).toMins(),
                ),
                backgroundColor: interviewersToColor[opt.value],
                borderColor: interviewersToColor[opt.value],
                borderWidth: 2,
                lineTension: 0.3,
                segment: {
                  borderColor: interviewersToColor[opt.value],
                },
              };
            })),
        {
          label: selectedBenchmarkLabel,
          data: [
            ...Array(props.metrics.longest_monologue_over_time.labels.length),
          ].map(
            () =>
              props.benchmarks.longest_monologue &&
              Timedelta.fromSecs(props.benchmarks.longest_monologue).toMins(),
          ),
          borderColor: bestPracticeColor,
          backgroundColor: 'transparent',
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
      options: genericOptions,
    },
  };

  const punctuality = {
    type:
      props.metrics.punctuality_over_time.values[0] instanceof Object
        ? ('line' as const)
        : ('bar' as const),
    options: {
      ...chartOptions(
        tooltipLabelFormatter(props.metrics.punctuality_over_time, (v) =>
          humanizeTime(v),
        ),
      ),
      scales: {
        y: {
          grid: {
            color: (ctx: ScriptableScaleContext, _options: AnyObject) =>
              ctx.tick.value === 0
                ? ChartOptions.defaults.borderColor
                : ('#FFF' as Color),
          },
          ticks: {
            callback: function (value) {
              if (Math.floor(value) === value) {
                return value && value + 'm';
              }
            },
          },
        },
      },
    },
    data: {
      labels: props.metrics.punctuality_over_time.labels.map((d) =>
        shortFormatDate(d),
      ),
      datasets: [
        ...(!(props.metrics.punctuality_over_time.values[0] instanceof Object)
          ? [
              {
                data: (
                  props.metrics.punctuality_over_time.values as number[]
                ).map((v) => v && Timedelta.fromSecs(v).toMins()),
                backgroundColor: allInterviewersColour,
                barPercentage: 1.0,
              },
            ]
          : selectedInterviewers.map((opt) => {
              return {
                label: opt.value,
                data: (
                  props.metrics.punctuality_over_time.values as {
                    [key: number]: number[];
                  }[]
                ).map(
                  (v) =>
                    v[opt.value] && Timedelta.fromSecs(v[opt.value]).toMins(),
                ),
                backgroundColor: interviewersToColor[opt.value],
                barPercentage: 1.0,
              };
            })),
        {
          type: 'line' as const,
          label: selectedBenchmarkLabel,
          data: [
            ...Array(props.metrics.punctuality_over_time.labels.length),
          ].map(
            () =>
              props.benchmarks.punctuality &&
              Timedelta.fromSecs(props.benchmarks.punctuality).toMins(),
          ),
          backgroundColor: 'transparent',
          borderColor: bestPracticeColor,
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
    },
  };

  const benchmarks = {
    interview_duration:
      props.benchmarks.interview_duration != null
        ? humanizeTime(props.benchmarks.interview_duration, true)
        : 'N/A',
    interactivity:
      props.benchmarks.interactivity != null
        ? Math.round(props.benchmarks.interactivity * 10) / 10
        : 'N/A',
    questions:
      props.benchmarks.questions != null
        ? Math.round(props.benchmarks.questions)
        : 'N/A',
    talk_ratio:
      props.benchmarks.talk_ratio != null
        ? `${Math.round(props.benchmarks.talk_ratio)}%`
        : 'N/A',
    talk_speed:
      props.benchmarks.talk_speed != null
        ? `${Math.round(props.benchmarks.talk_speed)} WPM`
        : 'N/A',
    longest_monologue:
      props.benchmarks.longest_monologue != null
        ? humanizeTime(props.benchmarks.longest_monologue, true)
        : 'N/A',
    punctuality:
      props.benchmarks.punctuality != null
        ? humanizeTime(props.benchmarks.punctuality, true)
        : 'N/A',
    interview_quality_score:
      props.benchmarks.interview_quality_score != null
        ? Math.round(props.benchmarks.interview_quality_score * 10) / 10
        : 'N/A',
  };

  return (
    <div className='mb-3 w-100'>
      <Filters
        selectedJobs={[]}
        selectedInterviewers={[]}
        selectedBenchmark={undefined}
        jobLoadOptions={jobLoadOptions}
        {...props}
      />
      {props.metrics.interview_count === 0 ? (
        <div className='mt-3'>
          <EmptyState
            title='No interview data was found'
            text={
              <p>
                Please try a different combination of filters or start recording
                your interviews today to take full advantage of Screenloop
                insights.
              </p>
            }
          />
        </div>
      ) : (
        <>
          <Row>
            <Col xs='12' sm='6' lg='3' className='mt-3'>
              <MetricCard
                title='Interviews'
                subtitle='TOTAL RECORDED INTERVIEWS'
                value={props.metrics.interview_count}
                trend={props.metricsTrend.interview_count}
              />
            </Col>
            <Col xs='12' sm='6' lg='3' className='mt-3'>
              <MetricCard
                title='Duration'
                subtitle='AVG INTERVIEW DURATION'
                value={humanizeTime(props.metrics.interviews_duration)}
                benchmarkName={
                  selectedBenchmarkLabel !== 'Best Practice'
                    ? selectedBenchmarkLabel
                    : null
                }
                benchmarkValue={benchmarks.interview_duration}
                trend={props.metricsTrend.interviews_duration}
              />
            </Col>
            <Col xs='12' sm='6' lg='3' className='mt-3'>
              <MetricCard
                title='Interactivity'
                subtitle='AVG INTERACTIVITY (0-10)'
                value={Math.round(props.metrics.interactivity * 10) / 10}
                benchmarkName={
                  selectedBenchmarkLabel !== 'Best Practice'
                    ? selectedBenchmarkLabel
                    : null
                }
                benchmarkValue={benchmarks.interactivity}
                trend={props.metricsTrend.interactivity}
                tooltipInfo='How often the conversation switches between participants'
              />
            </Col>
            <Col xs='12' sm='6' lg='3' className='mt-3'>
              <MetricCard
                title='Questions'
                subtitle='AVG QUESTIONS'
                value={Math.round(props.metrics.interview_automatic_questions)}
                benchmarkName={
                  selectedBenchmarkLabel !== 'Best Practice'
                    ? selectedBenchmarkLabel
                    : null
                }
                benchmarkValue={benchmarks.questions}
                trend={props.metricsTrend.interview_automatic_questions}
                tooltipInfo='AI detected questions by interviewer'
              />
            </Col>
          </Row>
          <Row>
            <Col xs='12' lg='5' className='mt-4'>
              <MetricCard<'doughnut'>
                title='Interview Quality Score'
                subtitle='AVG INTERVIEW QUALITY SCORE'
                value={`${getAverageInterviewQualityScore()}%`}
                benchmarkName={selectedBenchmarkLabel}
                benchmarkValue={`${benchmarks.interview_quality_score}%`}
                trend={props.metricsTrend.interview_quality_score}
                chartOptions={{ type: 'doughnut' as const, ...qualityScore }}
                breakdownValues={props.metrics.overall_user_score}
                colors={interviewersToColor}
                interviewers={orderedSelectedInterviewers}
                chartType='doughnut'
                score={props.metrics.interview_quality_score}
              />
            </Col>
            <Col xs='12' lg='7' className='mt-4'>
              <MetricCard
                title='Interview Quality Score History'
                subtitle={null}
                value={null}
                benchmarkName={selectedBenchmarkLabel}
                benchmarkValue={`${benchmarks.interview_quality_score}%`}
                trend={null}
                chartOptions={{ ...(qualityScoreOverTime as any) }}
                chartType='first row bar'
              />
            </Col>
          </Row>
          <Row>
            <Col xs='12' lg='7' className='mt-4'>
              <MetricCard<'line'>
                title='Talk Ratio'
                subtitle='AVG TALK RATIO'
                value={`${Math.round(props.metrics.talk_ratio)}%`}
                benchmarkName={selectedBenchmarkLabel}
                benchmarkValue={benchmarks.talk_ratio}
                trend={props.metricsTrend.talk_ratio}
                chartOptions={{ type: 'line' as const, ...ratio }}
                tooltipInfo='The % of time interviewer(s) spoke'
              />
            </Col>
            <Col xs='12' lg='5' className='mt-4'>
              <MetricCard
                title='Talk Speed'
                subtitle='AVG TALK SPEED'
                value={`${Math.round(props.metrics.talk_speed)} WPM`}
                benchmarkName={selectedBenchmarkLabel}
                benchmarkValue={benchmarks.talk_speed}
                trend={props.metricsTrend.talk_speed}
                chartOptions={{ ...(speed as any) }}
                tooltipInfo='How fast did interviewer(s) speak'
              />
            </Col>
          </Row>
          <Row>
            <Col xs='12' lg='5' className='mt-4'>
              <MetricCard<'line'>
                title='Longest Monologue'
                subtitle='AVG LONGEST MONOLOGUE'
                value={humanizeTime(props.metrics.longest_monologue)}
                benchmarkName={selectedBenchmarkLabel}
                benchmarkValue={benchmarks.longest_monologue}
                trend={props.metricsTrend.longest_monologue}
                chartOptions={{ type: 'line' as const, ...monologue }}
                tooltipInfo='Longest amount of time the interviewer(s) spoke continuously'
              />
            </Col>
            <Col xs='12' lg='7' className='mt-4'>
              <MetricCard
                title='Punctuality'
                subtitle='AVG TIME TO JOIN'
                value={humanizeTime(props.metrics.punctuality)}
                benchmarkName={selectedBenchmarkLabel}
                benchmarkValue={benchmarks.punctuality}
                trend={props.metricsTrend.punctuality}
                chartOptions={{ ...(punctuality as any) }}
                tooltipInfo='Did the interviewer(s) join the interview on time'
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
});
