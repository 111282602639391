import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as queryString from 'query-string';
import { LabelledSelect } from '../../../components/LabelledSelect';
import { LabelledDatePicker } from '../../../components/LabelledDatePicker';
import moment, { Moment } from 'moment';
import { SelectOption } from '../../../components/Select';
import { stringToDateRange } from '../../../utils/stringToDateRange';
import { SerializedDateRange } from '../../../entities/SerializedDateRange';
import classNames from 'classnames';
import { DateRange } from '../../../components/DateRangePicker';
import styles from './styles.module.scss';
import {
  FilterResult,
  MultiSelectAsyncFilter,
} from '../../../components/MultiSelectAsyncFilter';
import { LabelledMultiSelect } from '../../../components/LabelledMultiSelect';

interface PropTypes {
  startDate: string;
  endDate: string;
  dateRange: SerializedDateRange;
  jobLoadOptions: (search: string, page: number) => Promise<FilterResult>;
  interviewerOptions: SelectOption[];
  benchmarkOptions: SelectOption[];
  selectedJobs: SelectOption[];
  selectedInterviewers: SelectOption[];
  selectedBenchmark: SelectOption;
}

function getJobsFromHref(href) {
  const parsed = queryString.parse(href, { arrayFormat: 'bracket' });

  const jobIds = parsed.job_ids || [];
  const jobNames = parsed.job_names || [];

  const jobs = jobIds.map((id, index) => ({
    id,
    name: jobNames[index],
  }));

  return jobs.map((job) => ({ value: job.id, label: job.name }));
}

export function Filters(props: PropTypes) {
  const initialHref = window.location.search;

  const [selectedJobs, setSelectedJobs] = useState<SelectOption[]>(() =>
    getJobsFromHref(initialHref),
  );

  const [selectedInterviewers, setSelectedInterviewers] = useState(
    props.interviewerOptions.filter((opt) => opt.selected),
  );

  const [selectedBenchmark, setSelectedBenchmark] = useState(
    props.benchmarkOptions.find((opt) => opt.selected),
  );

  const [selectedDates, setSelectedDates] = useState({
    start: moment(props.startDate, 'YYYY-MM-DD'),
    end: moment(props.endDate, 'YYYY-MM-DD'),
    range: stringToDateRange(props.dateRange.serialized_val.replace(/_/g, ' ')),
  });

  const handleDateCallback = (start: Moment, end: Moment, range: DateRange) => {
    setSelectedDates({ start, end, range });
  };

  function getHref() {
    return queryString.stringify(
      {
        job_names: selectedJobs?.map((j) => j.label),
        job_ids: selectedJobs?.map((j) => j.value),
        user_ids: selectedInterviewers?.map((j) => j.value),
        benchmark: selectedBenchmark?.value,
        start_date: selectedDates.start?.format('YYYY-MM-DD HH:mm'),
        end_date: selectedDates.end?.endOf('day').format('YYYY-MM-DD HH:mm'),
        date_range: selectedDates.range,
      },
      { arrayFormat: 'bracket' },
    );
  }

  return (
    <div className='mb-3 w-100'>
      <Row className='gap-2 fs-5 h-100'>
        <Col xs='12' md='4' lg='2' className='mt-2'>
          <MultiSelectAsyncFilter
            isMulti
            label={'Job'}
            loadOptions={props.jobLoadOptions}
            onChange={setSelectedJobs}
            selected={selectedJobs}
            max={10}
          />
        </Col>
        <Col xs='12' md='4' lg='2' className='mt-2'>
          <LabelledMultiSelect
            options={props.interviewerOptions}
            selected={selectedInterviewers}
            label='Interviewers'
            placeholder='Select Interviewers'
            singleSelectedText='1 selected interviewer'
            manySelectedText='%d selected interviewers'
            onChange={setSelectedInterviewers}
            max={5}
          />
        </Col>
        <Col xs='12' md='4' lg='2' className='mt-2'>
          <LabelledSelect
            options={props.benchmarkOptions}
            selected={selectedBenchmark}
            label='Benchmark'
            onChange={setSelectedBenchmark}
            placeholder='Select Comparison'
          />
        </Col>
        <Col
          xs='12'
          md='4'
          lg='2'
          className='d-flex text-nowrap mt-2 align-bottom align-self-end'
        >
          <LabelledDatePicker
            label='Date'
            className='w-100'
            dateFilter={selectedDates}
            onChange={handleDateCallback}
          />
        </Col>
        <Col
          xs='12'
          md='4'
          xl='2'
          className={classNames(
            'd-flex text-nowrap align-bottom align-self-end',
            styles['filter-buttons-column'],
          )}
        >
          <div
            className={classNames(
              'd-flex gap-3 justify-content-between p-0',
              styles['filter-buttons'],
            )}
          >
            <Button
              type='button'
              color='secondary'
              onClick={() => (window.location.href = `?${''}`)}
            >
              Reset
            </Button>
            <Button
              type='button'
              color='primary'
              onClick={() => (window.location.href = `?${getHref()}`)}
            >
              Apply
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
